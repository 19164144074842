import * as React from 'react'
import Fade from 'react-reveal/Fade'
import { Helmet } from 'react-helmet'
import Footer from '../../../../components/Footer/Footer'
import './../tusk-2018.scss'
import Gallery, { useGallery } from '../../../../components/Gallery/Gallery'
import images from "../../../../components/Gallery/ImageLists/tusk-2018/quiz-night";

const QuizNight = () => {
  const { imageSelected, isOpen, onImageClicked, setIsOpen } = useGallery()

  return (
    <>
      <div id="quiz-night">
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
          <title>Emso</title>
          <meta name="description" content="Emso thank you page for Tusk 2018 Safaricom Marathon" />
        </Helmet>

        <section id="hero" className="hero standard wrap">
          <nav className="wrap nav">
            <div className="container cf">
              <a href="https://www.emso.com/" title="Back to Emso" id="logo">
                <img src="/images/tusk-2018/images/emso-logo.svg" alt="Emso logo" width="90" height="62" />
              </a>
            </div>
          </nav>
        </section>

        <main id="content" className="wrap" role="main">
          <div className="back-link-container">
            <div className="container cf">
              <a href="/tusk/2018" className="go-to-video" title="Go to video">
                <img
                  alt="arrow button down"
                  className="arrow-btn-down"
                  src="/images/tusk-2018/images/down-arrow-yellow.svg"
                  width="32"
                  height="32"
                />
                <span className="link">
                  <h6 className="yellow">Back to the 2018 Safaricom Marathon page</h6>
                </span>
              </a>
            </div>
          </div>

          <div className="page-title">
            <div className="container cf">
              <div className="row">
                <div className="col-base-12 col-xs-9 col-sm-7 col-lg-8">
                  <h1>Emso 2018 Quiz Night fundraising</h1>
                </div>
              </div>
            </div>
          </div>
          <Gallery images={images} imageSelected={imageSelected} isOpen={isOpen} setIsOpen={setIsOpen} />
          <Fade bottom>
            <section id="safaricom-galery" className="section-padding">
              <div className="container">
                <div className="row">
                  <div className="col-base-12 col-xs-9 col-sm-7 col-lg-8">
                    <h6>Photo gallery</h6>
                  </div>
                </div>
                <div className="row photos">
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9335.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9335.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9336.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9336.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9343.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9343.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9349.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9349.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9357.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9357.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9359.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9359.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9362.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9362.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9365.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9365.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9386.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9386.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9394.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9394.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9407.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9407.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9423.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9423.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9428.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9428.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9431.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9431.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9434.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9434.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9450.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9450.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9461.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9461.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9478.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9478.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9496.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9496.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9500.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9500.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9502.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9502.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9504.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9504.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9512.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9512.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9513.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9513.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9517.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9517.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9520.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9520.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9524.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9524.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9538.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9538.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9542.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9542.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9555.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9555.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9556.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9556.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9557.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9557.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9566.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9566.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9578.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9578.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9587.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9587.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9593.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9593.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9595.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9595.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9599.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9599.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9612.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9612.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9619.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9619.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9621.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9621.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9624.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9624.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9629.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9629.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9634.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9634.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9646.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9646.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9647.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9647.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9651.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9651.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9658.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9658.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9661.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9661.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2018/images/safari/_38A9672.jpg')
                      }}
                    >
                      <img src="/images/tusk-2018/images/safari/thumb-_38A9672.jpg" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Fade>
        </main>
      </div>
      <Footer />
    </>
  )
}

export default QuizNight
