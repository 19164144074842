
const quizNight = [
    '/images/tusk-2018/images/safari/_38A9335.jpg',
    '/images/tusk-2018/images/safari/_38A9336.jpg',
    '/images/tusk-2018/images/safari/_38A9343.jpg',
    '/images/tusk-2018/images/safari/_38A9349.jpg',
    '/images/tusk-2018/images/safari/_38A9357.jpg',
    '/images/tusk-2018/images/safari/_38A9359.jpg',
    '/images/tusk-2018/images/safari/_38A9362.jpg',
    '/images/tusk-2018/images/safari/_38A9365.jpg',
    '/images/tusk-2018/images/safari/_38A9386.jpg',
    '/images/tusk-2018/images/safari/_38A9394.jpg',
    '/images/tusk-2018/images/safari/_38A9407.jpg',
    '/images/tusk-2018/images/safari/_38A9423.jpg',
    '/images/tusk-2018/images/safari/_38A9428.jpg',
    '/images/tusk-2018/images/safari/_38A9431.jpg',
    '/images/tusk-2018/images/safari/_38A9434.jpg',
    '/images/tusk-2018/images/safari/_38A9450.jpg',
    '/images/tusk-2018/images/safari/_38A9461.jpg',
    '/images/tusk-2018/images/safari/_38A9478.jpg',
    '/images/tusk-2018/images/safari/_38A9496.jpg',
    '/images/tusk-2018/images/safari/_38A9500.jpg',
    '/images/tusk-2018/images/safari/_38A9502.jpg',
    '/images/tusk-2018/images/safari/_38A9504.jpg',
    '/images/tusk-2018/images/safari/_38A9512.jpg',
    '/images/tusk-2018/images/safari/_38A9513.jpg',
    '/images/tusk-2018/images/safari/_38A9517.jpg',
    '/images/tusk-2018/images/safari/_38A9520.jpg',
    '/images/tusk-2018/images/safari/_38A9524.jpg',
    '/images/tusk-2018/images/safari/_38A9538.jpg',
    '/images/tusk-2018/images/safari/_38A9542.jpg',
    '/images/tusk-2018/images/safari/_38A9555.jpg',
    '/images/tusk-2018/images/safari/_38A9556.jpg',
    '/images/tusk-2018/images/safari/_38A9557.jpg',
    '/images/tusk-2018/images/safari/_38A9566.jpg',
    '/images/tusk-2018/images/safari/_38A9578.jpg',
    '/images/tusk-2018/images/safari/_38A9587.jpg',
    '/images/tusk-2018/images/safari/_38A9593.jpg',
    '/images/tusk-2018/images/safari/_38A9595.jpg',
    '/images/tusk-2018/images/safari/_38A9599.jpg',
    '/images/tusk-2018/images/safari/_38A9612.jpg',
    '/images/tusk-2018/images/safari/_38A9619.jpg',
    '/images/tusk-2018/images/safari/_38A9621.jpg',
    '/images/tusk-2018/images/safari/_38A9624.jpg',
    '/images/tusk-2018/images/safari/_38A9629.jpg',
    '/images/tusk-2018/images/safari/_38A9634.jpg',
    '/images/tusk-2018/images/safari/_38A9646.jpg',
    '/images/tusk-2018/images/safari/_38A9647.jpg',
    '/images/tusk-2018/images/safari/_38A9651.jpg',
    '/images/tusk-2018/images/safari/_38A9658.jpg',
    '/images/tusk-2018/images/safari/_38A9661.jpg',
    '/images/tusk-2018/images/safari/_38A9672.jpg',
]

export default quizNight;